* {
  margin: 0;
  padding: 0;
}
.scroll_bar {
  scrollbar-width: thin;
  scrollbar-color: #def8db transparent;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #def8db;
}

$breakpoints: (
  "sm": "screen and (min-width: 650px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 1000px)",
  "xl": "screen and (min-width: 1200px)",
) !default;

@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin dm-container() {
  width: 45vw;
  height: 80vh;
  overflow-y: scroll;
  border: 1px solid #999;
  box-sizing: border-box;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
}

@mixin flex-between-center() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin pointer-cursor() {
  cursor: pointer;
}

@mixin question() {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px;
  margin-top: 12px;
  background-color: #bde3ff;
}

.container {
  overflow: auto;

  .res_projectDetail {
    .title_block {
      display: block;
      justify-content: space-between;

      @include mq(sm) {
        display: flex;
      }

      .title_inner {
        width: auto;

        @include mq(sm) {
          width: 350px;
        }

        @include mq(md) {
          width: 500px;
        }

        .title {
          font-size: 24px;
          margin-top: 32px;
        }
      }

      .article_buttons {
        margin-top: 24px;
        height: 42px;
        width: 250px;

        .open_button {
          width: 200px;
          height: 42px;
        }
      }
    }
  }

  .section {
    &__container {
      width: 100%;
      margin-top: 24px;
      padding: 24px 24px;
      background-color: #f7f7f7;

      .title {
        font-size: 20px;
      }

      .desc {
        color: #999;
        margin-bottom: 16px;
      }
    }

    &__inner {
      .question__block {
        .prop-title {
          font-size: 18px;
        }

        .prop-description {
          font-size: 14px;
          color: rgb(90, 90, 90);
        }

        .slider {
          width: 90%;
          margin-left: 12px;
        }

        .input_text {
          width: 80%;
          display: block;
          margin: 16px auto;
        }

        .evidence__container {
          margin-bottom: 48px;
          text-align: center;
          .evi_label {
            display: inline-block;
            margin: 12px 0;
          }

          .evi_text {
            width: 80%;
            margin-top: 12px;
            margin-left: 20px;
          }
        }
      }
    }
  }
  .article__wrapper {
    display: flex;

    .articleButton {
      width: 120px;
      height: 40px;
      background-color: rgb(215, 255, 220);
      text-align: center;
      line-height: 40px;
      margin-right: 20px;
      cursor: pointer;

      &.notActive {
        background-color: rgb(216, 216, 216);
      }

      &.open {
        background-color: rgb(215, 255, 220);
      }
    }
  }

  .status_buttons {
    width: 100%;
    display: flex;
    justify-content: center;

    &__inner {
      margin-top: 32px;
      width: 80px;
      height: 40px;
      position: relative;
    }
  }

  .res_modal__content {
    width: 600px;

    .moda_hedding {
      height: 80px;
      @include flex-between-center();
    }
  }

  .sectionDialog {
    &__inner {
      width: 600px;
      display: flex;

      .input__group {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .select__title {
        margin-top: 24px;
      }
      .select {
        margin-top: 12px;
        width: 120px;
      }
      .section__input {
        margin-top: 24px;
      }

      .section__list {
        width: 100%;
        margin-left: 40px;
        overflow-y: scroll;

        * {
          margin-bottom: 12px;
          padding: 12px 8px;
          border-radius: 5px;
          background-color: #e7f8db;
        }
      }
    }
  }

  .classy__container {
    @include dm-container();
    background-color: #f4f4f4;
    box-sizing: border-box;
    position: relative;

    .titleInfo {
      &__container {
        width: calc(45vw - 1px);
        height: 120px;
        padding: 0 12px;
        background-color: #fff2f2;
        border-right: 1px solid #999;
        border-bottom: 1px solid #999;
        box-sizing: border-box;
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
      }

      &__inner {
        width: 280px;
        height: 80px;
        background-color: #fffdcb;
        border: 1px solid #999;
        padding-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .sectionBlock {
      margin: 24px 12px;
      margin-top: 32px;
      padding: 24px 12px;
      background-color: #fffdcb;
      border: 1px solid #999;

      &:nth-child(2) {
        margin-top: 140px;
      }

      .section__titleBlock {
        width: 100%;
        @include flex-between-center();

        .section__title {
          display: flex;
          align-items: center;
        }
      }
      .section__description {
        color: #777777;
        font-size: 14px;
      }
    }

    .newQuestion__container {
      @include question();

      background-color: transparent;
      display: flex;
      justify-content: center;
    }

    .question {
      width: 100%;
      box-sizing: border-box;
      padding: 12px 12px;
      margin-top: 12px;
      background-color: #bde3ff;

      @include flex-between-center();

      .titleBlock {
        width: 100%;

        &.open {
          @include flex-between-center();
        }
        &.close {
          @include flex-between-center();

          .titleInner {
            display: flex;
            align-items: center;

            .QuestionTitle {
              width: 320px;
            }
          }
        }
      }

      .questionInner {
        width: 100%;
        &.open {
          .question__content {
            @include flex-center();
            flex-direction: column;
          }
        }
      }
      .questionInner.close {
        @include flex-between-center();
      }
      .buttonGroup {
        @include flex-center();
      }
      .deleteIcon {
        @include pointer-cursor();
      }
    }

    .addSection {
      display: flex;
      margin-left: 12px;
      padding-bottom: 40px;

      &__button {
        font-size: 24px;
        cursor: pointer;
      }

      &__text {
        cursor: pointer;
      }
    }
    .newSection {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 160px;

      &__button {
        width: 300px;
        height: 52px;
      }
    }
  }

  .preview {
    &__contaier {
      @include dm-container();
      display: flex;
      justify-content: center;
    }
    &__inner {
      width: 360px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 48px;
      .inputForm {
        width: 100%;
      }
      .slider {
        width: 90%;
        margin-left: 16px;
      }
      .evidence_input {
        width: 100%;
        margin-bottom: 32px;
      }
      .save_button {
        width: 80px;
        display: block;
        margin-top: 32px;
      }
    }
  }
}
.detail_dialogtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 24px;
  }

  .reload__button {
    width: 32px;
    height: 32px;
    color: rgb(95, 182, 95);
    cursor: pointer;
  }
}

.detail {
  &__container {
    width: 600px;
    box-sizing: border-box;
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #111;

    p {
      font-size: 18px;
    }

    .title {
      margin-top: 32px;
    }

    .longlist {
      margin-top: 24px;

      &__button {
        width: 220px;
        font-size: 12px;
        cursor: pointer;
      }

      &__reload {
        margin-left: 24px;
        height: 24px;
        border-radius: 12px;
      }
    }

    .url {
      margin-top: 60px;
    }
    .progress {
      margin-top: 12px;
    }
    .disagree {
      margin-top: 12px;
      color: #777777;
    }

    .excel_button {
      margin-top: 24px;
    }
  }
}
